.slide {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 70px) !important;
  overflow: hidden;

  &__play {
    height: 50px;
    width: 80px;
    background: #000;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 12px;
    border-radius: 5px;

    @media screen and (min-width: 767px) {
      margin-top: 24px;
    }

    &:before {
      content: '';
      display: block;
      background: white;
      height: 0;
      width: 0;
      border-width: 12px;
      border-left-width: 24px;
      margin-right: -18px;
      border-style: solid;
      border-left-color: white;
      border-bottom-color: #000;
      border-right-color: #000;
      border-top-color: #000;
    }

    &:hover {
      background-color: #fff;

      &:before {
        border-bottom-color: #fff;
        border-right-color: #fff;
        border-top-color: #fff;
        border-left-color: #000;
      }
    }
  }

  &__video {
    position: absolute;
    top: 50%; left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    zoom: 5;

    @media screen and (min-width: 767px) {
      zoom: 6;
    }

    @media screen and (min-width: 992px) {
      zoom: 7;
    }

    @media screen and (min-width: 1200px) {
      zoom: 8;
    }

    @media screen and (min-width: 1400px) {
      zoom: 9;
    }
  }

  &__overlay {
    padding: 2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;

    @media screen and (min-width: 992px) {
      padding: 4rem 2rem;
    }

    @media screen and (min-width: 1200px) {
      padding: 8rem 0;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__title {
    display: block;
    margin: 0;
    line-height: 1;
  }

  &__subtitle {
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px solid;
    margin: 0 0 12px;
    line-height: 1;
    padding-bottom: 0.5rem;
    font-size: 1rem;
  }

  //&__tagline {
  //  margin: 3rem 0 0;
  //}

  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 0;

    &.active {
      display: block;
      z-index: 999999;
      visibility: visible;
      opacity: 1;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.85);
      width: 100vw;
      height: 100vh;
    }

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      padding-top: 36%;
      padding-right: 64%;
    }

    &-close {
      position: absolute;
      top: -40px;
      right: -7px;
      width: 30px;
      line-height: 30px;
      background: transparent;
      color: #fff;
      text-align: center;
      border-radius: 100%;
      cursor: pointer;
      z-index: 999;
    }

    &-source {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #000;
    }
  }

  @media screen and (min-width: 992px) {
    height: calc(100vh - 111px) !important;
  }

  @media screen and (min-width: 1200px) {
    height: calc(100vh - 171px) !important;
  }
}
